.html {
  height: 100%;
}

.body {
  min-height: 100%;
  font-family: Poppins, sans-serif;
  background-color: red;
}

* {
  box-sizing: border-box;
}
